// Vue
import router from "@/router";
import axios from "axios";
import Vue from "vue";
// Vuex
import Vuex from "vuex";
import { AuthUser } from "@/core/_models/authUser.model";
// Persisted State
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

interface AppState {
  currentUser: AuthUser | undefined;
  canLogout: boolean;
}

const appState: AppState = {
  currentUser: undefined,
  canLogout: true
};

export default new Vuex.Store({
  // ! State
  state: appState,

  // * Getters
  getters: {
    currentUser(state: AppState) {
      return state.currentUser;
    },

    isAuthenticated(state: AppState) {
      return state.currentUser !== undefined;
    },

    canLogout(state: AppState){
      return state.canLogout;
    }
  },

  // * Mutations
  mutations: {
    LOGIN(state: AppState, currentUser: AuthUser): void {
      state.currentUser = currentUser;
    },

    LOGOUT(state: AppState): void {
      state.currentUser = undefined;
    },

    CANLOGOUT(state: AppState, canLogout: boolean): void {
      state.canLogout = canLogout;
    }
  },

  // * Actions
  actions: {
    login(context: any, currentUser: AuthUser) {
      context.commit("LOGIN", currentUser);
      router.push({ name: "Home" });
    },

    logout(context: any) {
      context.commit("LOGOUT");
      router.push({ name: "Login" });
    },

    canLogout(context:any, canLogout: boolean){
      context.commit("CANLOGOUT", canLogout);
    }
  },

  plugins: [createPersistedState()]
});
