
















// Vue
import Vue from "vue";
// Components
import AppHeaderBar from "@/core/components/layout/AppHeaderBar.vue";

export default Vue.extend({
  name: "Layout",
  components: { AppHeaderBar },

  methods: {
    isFlexCentered(): boolean {
      if (this.$route.name === "Login") return true;
      if (this.$route.name === "ForgotPassword") return true;

      return false;
    }
  }
});
