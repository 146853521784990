




































// Vue
import Vue from "vue";
// Vuex
import { mapActions, mapGetters } from "vuex";
// API
import { UserClient } from "@/core/api/PharmacyReimbursementApi";
// PNotify
import { success, error, notice } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import "@pnotify/core/dist/BrightTheme.css";
import "@pnotify/confirm/dist/PNotifyConfirm.css";
import * as Confirm from "@pnotify/confirm";

const userClient = new UserClient();

export default Vue.extend({
  name: "AppHeaderBar",
  data: function () {
    return {
      userActive: false
    };
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }),
    ...mapGetters({ canLogout: "canLogout" })
  },

  methods: {
    ...mapActions({ logout: "logout" }),

    async submitLogout(): Promise<void> {
      if (this.canLogout) {
        try {
          // Update user online status
          await userClient.userOffline(this.currentUser.accountID);
        } catch (ex) {
          console.log(ex);
        } finally {
          this.logout();
        }
      } else {
        this.toastError();
      }
    },
    async keepAliveUser() {
      if (this.userActive) {
        await userClient.ping(this.currentUser.accountID);

        setTimeout(() => {
          this.keepAliveUser();
        }, 60000);
      }
    },
    async updateUserStatus(isOnline: boolean): Promise<void> {
      this.userActive = isOnline;

      if (this.currentUser != null) {
        if (isOnline) {
          // user online
          await userClient.userOnline(this.currentUser.accountID);

          this.keepAliveUser();
        } else {
          // user offline
          await userClient.userOffline(this.currentUser.accountID);
        }
      }
    },
    toastError(): void {
      error({
        title: "Error",
        text: "You can not logout because there are pending or in-progress requests. Please complete all the requests.",
        hide: true,
        closer: false,
        sticker: false,
        destroy: true,
        modules: new Map([
          [
            Confirm,
            {
              confirm: true,
              buttons: [
                {
                  text: "Ok",
                  primary: true,
                  click: (notice: any) => notice.close()
                }
              ]
            }
          ]
        ])
      });
    }
  },
  created() {
    // on page load
    window.addEventListener.call(window, "load", async (e) => await this.updateUserStatus(true));
    // on page is focused/active
    window.addEventListener.call(window, "focus", async (e) => await this.updateUserStatus(true));
    // before page unload
    window.addEventListener.call(window, "beforeunload", async (e) => this.updateUserStatus(false));
  }
});
