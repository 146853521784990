import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/core/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  // * Home
  {
    path: "/home",
    name: "Home",
    component: () => import("@/views/home/Home.vue")
  },

  // * Auth
  // Login
  {
    path: "/auth/login",
    name: "Login",
    component: () => import("@/views/auth/Login.vue")
  },
  // Forgot Password
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/auth/ForgotPassword.vue")
  }
];

const router = new VueRouter({
  routes
});

const routeNames: string[] = [];
routes.forEach((element) => {
  routeNames.push(element.name!);
});

// Before each page route.
router.beforeEach((to, from, next) => {
  // Scroll page to top on every route change
  window.scrollTo(0, 0);

  // Redirect to Login if user tries to navigate to a Route that doesn't exist.
  if (!routeNames.includes(to.name!)) {
    next({ name: "Home" });
    return;
  }

  // User is NOT Authenticated; redirect to login.
  if (!store.getters.isAuthenticated && to.name !== "Login" && to.name !== "ForgotPassword") {
    next({ name: "Login" });
    return;
  }

  // User IS authenticated.
  if (store.getters.isAuthenticated) {
    // If an authenticated User attempts to navigate to login; redirect to Home.
    if (to.name === "Login") {
      next({ name: "Home" });
      return;
    }
    next();
    return;
  }

  next();
});

export default router;
