import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },

  theme: {
    dark: false,
    themes: {
      // Light Themeing
      light: {
        primary: "#00AFE9",
        primaryDark: "#006687",
        secondary: "#253036",
        tertiary: "#616161"
      },

      // Dark Themeing
      dark: {
        primary: "#00AFE9",
        primaryDark: "#006687",
        secondary: "#253036",
        tertiary: "#616161"
      }
    }
  }
});
