// Vue
import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/core/store";
// Vuetify
import vuetify from "@/core/plugins/vuetify";
// BootstrapVue
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// Axios
import axios from "axios";

// Axios config
axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL}`;

Vue.config.productionTip = false;

// Usings
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
