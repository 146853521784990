













import Vue from "vue";
import Layout from "@/core/components/layout/_Layout.vue";

export default Vue.extend({
  name: "App",
  data(){
    return {
      appVersionNumber: process.env.VUE_APP_VERSION_NUMBER,
    }
  },
  components: { Layout }
});
